import { Selector } from '@ngxs/store';

import { Language } from '@customer-portal/shared';

import { ProfileState, ProfileStateModel } from '../profile.state';

export class ProfileLanguageSelectors {
  @Selector([ProfileLanguageSelectors._languageLabel])
  static languageLabel(languageLabel: Language): Language {
    return languageLabel;
  }

  @Selector([ProfileState])
  private static _languageLabel(state: ProfileStateModel): Language {
    return state?.languageLabel;
  }
}
