import { Selector } from '@ngxs/store';

import { ProfileInformationModel } from '../../models';
import { ProfileState, ProfileStateModel } from '../profile.state';

export class ProfileSelectors {
  @Selector([ProfileState])
  static profileInformation(state: ProfileStateModel): ProfileInformationModel {
    return state.information;
  }

  @Selector([ProfileState])
  static submitSettingsStatus(state: ProfileStateModel): boolean {
    return state.submitSettingsStatus;
  }
}
