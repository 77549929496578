import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { PROFILE_QUERY } from '../../../graphql';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private clientName = 'contact';

  constructor(private readonly apollo: Apollo) {}

  getProfileData(email: string): Observable<any> {
    return this.apollo
      .use(this.clientName)
      .query({
        query: PROFILE_QUERY,
        variables: {
          email,
        },
      })
      .pipe(map((results: any) => results?.data?.userProfile));
  }
}
