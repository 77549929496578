import { ProfileDto } from '../../dtos';
import { ProfileModel } from '../../models';

export class ProfileMapperService {
  static mapToProfileItemModel(dto: ProfileDto): ProfileModel | null {
    if (!dto?.data) {
      return null;
    }

    const { data } = dto;
    const profile = data;

    return {
      information: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        displayName: profile.displayName,
        country: profile.country,
        region: profile.region,
        email: profile.email,
        phone: profile.phone,
        portalLanguage: profile.portalLanguage,
        communicationLanguage: profile.communicationLanguage,
        jobTitle: profile.jobTitle,
        languages: [],
      },
    };
  }
}
