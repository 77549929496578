import { gql } from 'apollo-angular';

export const PROFILE_LANGUAGE_MUTATION = gql`
  mutation UpdateProfileLanguage($email: String!, $language: String!) {
    updateProfile(
      updateProfileRequest: { email: $email, portalLanguage: $language }
    ) {
      data {
        email
        portalLanguage
      }
      isSuccess
      message
      errorCode
    }
  }
`;
