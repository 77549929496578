import { gql } from 'apollo-angular';

export const PROFILE_LANGUAGE_QUERY = gql`
  query GetProfileLanguage($email: String) {
    userProfile(email: $email) {
      data {
        portalLanguage
      }
      isSuccess
      errorCode
      message
    }
  }
`;
