import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Action, State, StateContext } from '@ngxs/store';
import { filter, map, tap } from 'rxjs';

import {
  Language,
  LocaleService,
  PROFILE_EMAIL,
} from '@customer-portal/shared';

import { ProfileLanguageDto } from '../dtos';
import { ProfileInformationModel } from '../models';
import {
  ProfileLanguageMapperService,
  ProfileLanguageService,
  ProfileMapperService,
  ProfileService,
} from '../services';
import {
  LoadProfileData,
  LoadProfileLanguage,
  LoadProfileLanguageSuccess,
  UpdateProfileLanguage,
  UpdateProfileLanguageSuccess,
  UpdateSubmitSettingsStatus,
} from './actions';

export interface ProfileStateModel {
  information: ProfileInformationModel;
  submitSettingsStatus: boolean;
  languageLabel: Language;
}

const defaultState = {
  information: {
    firstName: '',
    lastName: '',
    displayName: '',
    country: '',
    region: '',
    email: '',
    phone: '',
    portalLanguage: '',
    communicationLanguage: 'English',
    jobTitle: '',
    languages: [],
  },
  submitSettingsStatus: false,
  languageLabel: Language.English,
};

@State<ProfileStateModel>({
  name: 'profile',
  defaults: defaultState,
})
@Injectable()
export class ProfileState {
  constructor(
    private readonly localeService: LocaleService,
    private profileService: ProfileService,
    private readonly profileLanguageService: ProfileLanguageService,
    private readonly translocoService: TranslocoService,
  ) {}

  @Action(LoadProfileData)
  loadProfileData(ctx: StateContext<ProfileStateModel>) {
    return this.profileService.getProfileData(PROFILE_EMAIL).pipe(
      filter((profileDto) => profileDto.isSuccess),
      tap((profileDto) => {
        const profileData =
          ProfileMapperService.mapToProfileItemModel(profileDto);

        if (profileData?.information) {
          ctx.patchState({ information: profileData?.information });
        }
      }),
    );
  }

  @Action(UpdateSubmitSettingsStatus)
  updateSubmitSettingsStatus(
    ctx: StateContext<ProfileStateModel>,
    { submitSettingsStatus }: UpdateSubmitSettingsStatus,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      submitSettingsStatus,
    });
  }

  @Action(LoadProfileLanguage)
  loadProfileLanguage(ctx: StateContext<ProfileStateModel>) {
    return this.getProfileLanguage().pipe(
      tap((data) => {
        ctx.dispatch(
          new LoadProfileLanguageSuccess(
            ProfileLanguageMapperService.mapToProfileLanguageModel(data),
          ),
        );
      }),
    );
  }

  @Action(LoadProfileLanguageSuccess)
  loadProfileLanguageSuccess(
    ctx: StateContext<ProfileStateModel>,
    { languageLabel }: LoadProfileLanguageSuccess,
  ) {
    this.localeService.setLocale(languageLabel);
    this.translocoService.setActiveLang(languageLabel);
    ctx.patchState({ languageLabel });
  }

  @Action(UpdateProfileLanguage)
  updateProfileLanguage(
    ctx: StateContext<ProfileStateModel>,
    { languageLabel }: UpdateProfileLanguage,
  ) {
    return this.profileLanguageService
      .updateProfileLanguage(PROFILE_EMAIL, languageLabel)
      .pipe(
        tap((dto: ProfileLanguageDto) => {
          ctx.dispatch(
            new UpdateProfileLanguageSuccess(
              ProfileLanguageMapperService.mapToProfileLanguageModel(dto?.data),
            ),
          );
        }),
      );
  }

  @Action(UpdateProfileLanguageSuccess)
  updateProfileLanguageSuccess(
    ctx: StateContext<ProfileStateModel>,
    { languageLabel }: UpdateProfileLanguageSuccess,
  ) {
    this.localeService.setLocale(languageLabel);
    ctx.patchState({ languageLabel });
  }

  private getProfileLanguage(email = PROFILE_EMAIL) {
    return this.profileLanguageService
      .getProfileLanguage(email)
      .pipe(
        map((dto: ProfileLanguageDto) =>
          dto?.isSuccess && dto?.data
            ? dto.data
            : { portalLanguage: Language.English },
        ),
      );
  }
}
