import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { ProfileInformationModel } from '../../models';
import { LoadProfileData, UpdateSubmitSettingsStatus } from '../actions';
import { ProfileSelectors } from '../selectors/profile.selectors';

@Injectable({ providedIn: 'root' })
export class ProfileStoreService {
  get profileInformation(): Signal<ProfileInformationModel> {
    return this.store.selectSignal(ProfileSelectors.profileInformation);
  }

  get submitSettingsStatus(): Signal<boolean> {
    return this.store.selectSignal(ProfileSelectors.submitSettingsStatus);
  }

  constructor(private store: Store) {}

  @Dispatch()
  loadProfileData = () => new LoadProfileData();

  @Dispatch()
  updateSubmitSettingsStatus = (isValid: boolean) =>
    new UpdateSubmitSettingsStatus(isValid);
}
