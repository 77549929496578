import { signal } from '@angular/core';

const profileSettingsMock = signal({
  languages: [
    {
      languageName: 'English',
      isSelected: false,
    },
  ],
  jobTitle: 'Certification manager',
  communicationLanguage: 'English',
});

export const createProfileStoreServiceMock = () => ({
  loadProfileData: jest.fn(),
  profileSettings: profileSettingsMock,
  updateSubmitSettingsStatus: jest.fn(),
  profileInformation: signal({
    firstName: '',
    lastName: '',
    displayName: '',
    country: '',
    region: '',
    email: '',
    phone: '',
    communicationLanguage: 'English',
    jobTitle: 'Certification manager',
    portalLanguage: 'English',
    languages: [{ isSelected: false, languageName: 'English' }],
  }),
});
